




































import Vue from 'vue'
import { formatDateWithoutYear } from '@/lib/date-util'
import { Calender } from '@/pages/appointment/types'

export type DataType = {
  modal: boolean;
}

export default Vue.extend({
  name: 'DateField',
  props: {
    value: {
      type: String,
      default: null
    },
    rules: {
      type: Array,
      required: false,
      default: null
    },
    calender: {
      type: Object,
      required: true
    }
  },
  data (): DataType {
    return {
      modal: false
    }
  },
  computed: {
    day: {
      get (): string | null {
        return this.value
      },
      set (newVal: string): void {
        if (this.value !== newVal) this.$emit('input', newVal)
      }
    },
    formatDay (): string | null {
      return formatDateWithoutYear(this.day)
    },
    minDate (): string {
      const date: Date = new Date()
      date.setDate(date.getDate() + 2)
      return date.toISOString().substr(0, 10)
    },
    maxDate (): string {
      const date: Date = new Date()
      date.setMonth(date.getMonth() + 1)
      return date.toISOString().substr(0, 10)
    }
  },
  methods: {
    allowedDates (val: string): boolean {
      if (val in this.calender) {
        const cal: Calender = this.calender[val]
        return cal.startTime1 != null || cal.startTime2 != null
      }
      return false
    }
  }
})
