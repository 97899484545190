



































































































































import Vue from 'vue'
import { mapActions, mapGetters } from 'vuex'
import DateFieldDialog from '@/pages/appointment/components/DateFieldDialog.vue'
import TimeField from '@/pages/appointment/components/TimeField.vue'
import TitleComp from '@/pages/appointment/components/Title.vue'
import { apiUrl } from '@/lib/api-util'
import { AxiosResponse } from 'axios'
import { Calender, CalenderDictionary } from '@/pages/appointment/types'

export type DataType = {
  valid: boolean;
  requiredRules: Function[];
  calender: CalenderDictionary;
  overlay: boolean;
}

export default Vue.extend({
  name: 'Date',
  components: {
    DateFieldDialog,
    TimeField,
    TitleComp
  },
  data (): DataType {
    return {
      valid: false,
      requiredRules: [
        (v: string) => !!v || '選択してください'
      ],
      calender: {},
      overlay: true
    }
  },
  computed: {
    ...mapGetters('appointments', [
      'initialDiagnosis',
      'selectedMenu',
      'firstHopeDay',
      'firstHopeHour',
      'secondHopeDay',
      'secondHopeHour',
      'thirdHopeDay',
      'thirdHopeHour',
      'complete'
    ]),
    firstDay: {
      get (): Date | null {
        return this.firstHopeDay
      },
      set (value: Date): void {
        if (this.firstHopeDay !== value) {
          this.setFirstHopeDay(value)
          this.setFirstHopeHour(null)
        }
      }
    },
    firstHour: {
      get (): string | null {
        return this.firstHopeHour
      },
      set (value: string): void {
        this.setFirstHopeHour(value)
      }
    },
    firstHourItems (): string[] {
      return this.createHourItems(this.firstDay)
    },
    secondDay: {
      get (): Date | null {
        return this.secondHopeDay
      },
      set (value: Date): void {
        if (this.secondHopeDay !== value) {
          this.setSecondHopeDay(value)
          this.setSecondHopeHour(null)
        }
      }
    },
    secondHour: {
      get (): string | null {
        return this.secondHopeHour
      },
      set (value: string): void {
        this.setSecondHopeHour(value)
      }
    },
    secondHourItems (): string[] {
      return this.createHourItems(this.secondDay)
    },
    thirdDay: {
      get (): Date | null {
        return this.thirdHopeDay
      },
      set (value: Date): void {
        if (this.thirdHopeDay !== value) {
          this.setThirdHopeDay(value)
          this.setThirdHopeHour(null)
        }
      }
    },
    thirdHour: {
      get (): string | null {
        return this.thirdHopeHour
      },
      set (value: string): void {
        this.setThirdHopeHour(value)
      }
    },
    thirdHourItems (): string[] {
      return this.createHourItems(this.thirdDay)
    }
  },
  created (): void {
    this.$http
      .get(apiUrl('fetch_calender_list/')).then((res: AxiosResponse) => {
        res.data.calenderList.forEach((cal: Calender) => {
          this.calender[cal.date] = cal
        })
        // 別画面から戻ってきた際に選択日時の強制更新が必要な場合がある
        if (this.firstDay !== null) {
          const firstDayBk = this.firstDay
          const firstHourBk = this.firstHour
          this.firstDay = null
          this.firstDay = firstDayBk
          this.firstHour = firstHourBk
        }

        if (this.secondDay !== null) {
          const secondDayBk = this.secondDay
          const secondHourBk = this.secondHour
          this.secondDay = null
          this.secondDay = secondDayBk
          this.secondHour = secondHourBk
        }

        if (this.thirdDay !== null) {
          const thirdDayBk = this.thirdDay
          const thirdHourBk = this.thirdHour
          this.thirdDay = null
          this.thirdDay = thirdDayBk
          this.thirdHour = thirdHourBk
        }

        this.overlay = false
      }).catch((error: Error) => {
        console.error(error.message)
        this.overlay = false
      })
  },
  methods: {
    ...mapActions('appointments', [
      'setFirstHopeDay',
      'setFirstHopeHour',
      'setSecondHopeDay',
      'setSecondHopeHour',
      'setThirdHopeDay',
      'setThirdHopeHour',
      'setDate'
    ]),
    clickNextBtn (): void {
      if (!this.complete) {
        this.setDate()
        this.$router.push({ name: 'page-profile' })
      }
    },
    createHourItems (date: Date | null): string[] {
      if (date === null) {
        return []
      }
      const cal: Calender = this.calender[String(date)]
      if (cal == null) {
        return []
      }
      const result: string[] = []
      let start = 0
      let end = 0
      if (cal.startTime1 != null && cal.endTime1) {
        start = Number(cal.startTime1.slice(0, cal.startTime1.indexOf(':')))
        end = Number(cal.endTime1.slice(0, cal.endTime1.indexOf(':'))) - 1
        for (let t: number = start; t <= end; t++) {
          if (t === 12 && this.initialDiagnosis) {
            continue
          }
          result.push(String(t))
        }
      }
      if (cal.startTime2 != null && cal.endTime2) {
        start = Number(cal.startTime2.slice(0, cal.startTime2.indexOf(':')))
        end = Number(cal.endTime2.slice(0, cal.endTime2.indexOf(':'))) - 1
        for (let t: number = start; t <= end; t++) {
          result.push(String(t))
        }
      }
      return result
    }
  }
})
