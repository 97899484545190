

















import Vue from 'vue'
import SelectField from '@/pages/appointment/components/SelectField.vue'

export default Vue.extend({
  name: 'TimeField',
  components: {
    SelectField
  },
  props: {
    value: {
      type: String,
      default: null
    },
    items: {
      type: Array,
      default: () => []
    },
    rules: {
      type: Array,
      required: false,
      default: null
    }
  },
  computed: {
    time: {
      get (): string | null {
        return this.value
      },
      set (newVal: string): void {
        if (this.value !== newVal) this.$emit('input', newVal)
      }
    }
  }
})
